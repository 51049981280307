<template>
  <div class="home" @mousemove="mouseY" @mouseleave="offset = 0">
    <v-row justify="center" align="end">
        <div class="d-flex flex-column align-center titles">
          <p class="logo-title">na<span class="title-i">i</span>m<span class="title-d">d</span></p>
          <p class="logo-subtitle">connecting generations</p>
        </div>
        <img ref="logo" src="../assets/logo.svg" alt="logo" class="logo-img" :style="{'transform': 'rotate(' + offset + 'deg)'}">
    </v-row>
    <p class="label">coming soon</p>

  </div>
</template>

<script>
  export default {
    name: 'HomePage',

    data: () => ({
      offset: 0
    }),
    computed: {
      imgPosition () {
        const logo = this.$refs.logo.getBoundingClientRect().top
        return logo
      }
    },
    methods: {
      mouseY (event) {
        const logo = this.imgPosition
        const y = event.clientY
        var diffY = logo - y + 90;
        
        if (diffY < -250) diffY = -250
        
        this.offset = diffY / (Math.PI * 2)
      }
    }
  }
</script>
<style scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
  padding-top: 100px
}
.logo-title {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-size: 100px;
  line-height: 1;
  letter-spacing: 3px;
  margin-bottom: 0;
}
.logo-subtitle {
  font-family: roboto;
  color: lightgrey;
  letter-spacing: 4px;
}
.title-i {
  color: #FE0000;
}
.title-d {
  color: #FE0000;
  font-style: italic;
}
.logo-img {
  margin-left: 40px;
  width: 75px;
  height: auto; 
}
.label {
  text-align: center;
  color: white;
  font-family: 'Roboto';
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 200;
  padding-top: 100px;
  font-size: 40px;
}

@media only screen and (max-width: 600px) {
  .titles {
    width: 100%;
    margin-bottom: 80px
  }
  .logo-img {
    margin-left: 0
  }
}
</style>
